import Vue from 'vue'
import App from './App.vue'
import * as Utils from '@/utils/format'
// import 'lib-flexible'
import './utils/rem2.js'

// 引入router插件
import VueRouter from 'vue-router'
//引入中英文切换插件vue-i18n
import VueI18n from 'vue-i18n'
// 加载 element 组件库
import ElementUI from 'element-ui'
// 加载 element 组件库的样式
import 'element-ui/lib/theme-chalk/index.css'

// 循环遍历 timeUtils 中的方法，并注册为全局过滤器
Object.keys(Utils).forEach(key => {
  Vue.filter(key, Utils[key]);
});

// 全局注册 element 组件库
Vue.use(ElementUI)
Vue.use(VueI18n) // 挂载
const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识
  messages: {
    'zh-CN': require('./lang/zh'),   // 通过require引入中文语言包
    'en-US': require('./lang/en')    // 通过require引入英文语言包
  }
})

//使用插件
Vue.use(VueRouter)
// 创建router文件夹 引入实例
import router from './router'

// 关闭生产提示
Vue.config.productionTip = false

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
