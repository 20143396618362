export const m = {
  index: '首页', // 导航-首页
  about: '关于我们', // 导航-关于我们
  business: '公司业务', // // 导航-公司业务
  business2: '公司业务', // // 导航-公司业务
  case: '会员单位', // 导航-合作案例
  news: '新闻资讯', // 导航-新闻资讯
  call: '联系我们', // 导航-联系我们
  sdInfo: '供求信息',
  Login: '登录', // 导航-登录
  Registration: '注册', // // 导航-注册
  brief: '郑州新荣登国际贸易有限公司是一家集进口、出口为一体的贸易公司，在粮食进口方面：经营品种麦、荞麦、燕麦、绿豆、豌豆、亚麻籽等；同时开展国内先进技术农机公司的出口代理业务，目前农机类型主要有：云控制气吸播种机、驱动耙、液压翻转犁等.',
  trade: '货物进出口', // 公司业务 - 贸易服装
  equipment: '技术进出口', // 公司业务 - 医用电子仪器设备
  jewelry: '进出口代理', // 公司业务 - 鞋帽饰品
  tools: '食品进出口', //  公司业务 - 五金工具
  quick: '快速导航',
  find: '找到我们',
  address: '河南自贸试验区郑州片区（经开）第八大街160号 附9号2层202',
  service: '咨询电话',
  filing: '郑州新荣登国际贸易有限公司',
  filing2: '京公网安备110123456789456号',
  vx: '微信',
  addressText: '公司地址',
  iponeType: '联系方式',
  zhuceyouxiang: '注册邮箱',
  emil: '公司邮箱',
  console: '控制台',

  cIndex: '首页',
  cOrder: '订单管理',
  cUserInfo: '个人信息',
  weclonme: '欢迎您',
  backIndex: '返回官网',
  tuiChu: '退出登录',
  fontSize: '字号',
  phone: '手机号码',
  chaxun: '查询',
  yongHuXiangQing: '用户详情',
  userHeard: '头像',
  gongsixiangqing: '公司详情',
  jingYingPingZhong: '经营品种',
  guanbi: '关闭',
  queren: '确认',
  jiChuXinXi: '基础信息',
  duiGongZhangHaoXinXi: '对公账户信息',
  tianJiaDuiGongZhangHao: '添加对公账户信息',
  biZhong: '币种',
  shouxirenmingcheng: '受益人名称',
  shouxirendizhi: '受益人地址',
  shouxirenzhanghao: '受益人账号',
  shouxirenkaihuhang: '受益人开户行',
  kaihuhangdizhi: '开户行地址',
  shanchu: '删除',
  tijiao: '提交',
  quxiao: '取消',
  tan1: '确认删除此对公账户信息?',
  tishi: '提示',
  shanchuchenggong: '删除成功!',
  qingshurubizhong: '请输入币种',
  qingshurushouyirenmingren: '请输入受益人名称',
  qingshurushouqirendizhi: '请输入受益人地址',
  qingshurushouqirenzhanghao: '请输入受益人账号',
  qingshurushouqirenkaihuhang: '请输入受益人开户行',
  qingshurukaihuhangdizhi: '请输入开户行地址',
  qingshuruzihao: '请输入字号',
  qingshurulianxifangshi: '请输入联系方式',
  qingshurujingyingpinzhong: '请输入经营品种',
  qingshangchuantouxiang: '请上传头像',
  qingshurugongsixiangqing: '请输入公司详情',
  cPhone: '手机号码',
  zhuangtai: '状态',
  qingxuanzezaixianzhuangtai: '请选择订单状态',
  type1: '全部',
  type2: '待发送',
  type3: '待支付',
  type4: '已支付',
  type5: '已完成',
  type6: '已取消',
  chongzhi: '重置',
  tianjiadingdan: '添加订单',
  daochu: '导出',
  shuaxin: '刷新',
  noneOrder: '没有订单哦~',
  xuhao: '序号',
  dingdanhao: '订单号',
  hetonghao: '合同号',
  shangpinmingcheng: '商品名称',
  shangpinmiaoshu: '商品描述',
  fukuanjiage: '付款价格',
  zhifushijian: '支付时间',
  wu: '无',
  dingdanzhuangtai: '订单状态',
  zhifupingzheng: '支付凭证',
  chuangjianshijian: '创建时间',
  zhiliangguige: '质量规格',
  jiaohuoqi: '交货期',
  zhifufangshi: '支付方式',
  chakanzhuangtai: '查看状态',
  Viewed: '已查看',
  Viewed2: '未查看',
  caozuo: '操作',
  edit: '编辑',
  fasong: '发送',
  shangchuanpinzheng: '上传凭证',
  wanchengdingdan: '完成订单',
  querenquxiao: '确认取消？',
  qingtianxiequxiaoyuanyin: '请填写取消原因',
  querenfasongdingdan: '确认发送订单?',
  fasongchenggong: '发送成功',
  querenwanchengdingdan: '确认完成订单?',
  dingdanwancheng: '订单完成',
  shuaxinchenggong: '刷新成功',
  jiage: '价格',
  xuanzeriqi: '选择日期',
  pay1: '电汇',
  pay2: 'D/P即期',
  pay3: 'D/P30天',
  pay4: '即期信用证',
  fasonggeiyonghu: '发送给用户',
  qingxuanzefasonggeiyonghu: '请输入发送的用户邮箱',
  pingzheng: '凭证',
  shangchuanchenggong: '上传成功',
  daochuxuanze: '导出选择',
  yonghushoujihao: '用户邮箱',
  kaishishijian: '开始时间',
  jieshushijian: '结束时间',
  zuihouyige: '不填即为导出全部用户',
  bianjidingdan: '编辑订单',
  tijiaochenggong: '提交成功',

  loginTitle: '欢迎回来，请登录您的账号',
  qingshurunidezhanghao: '请输入您的手机号',
  qingshurunidemima: '请输入您的密码',
  qingshuyanzhengma: '请输入验证码',
  wangjimima: '忘记密码',
  qingzhucehuiyuanzhanghao: '请注册会员账号',
  qingshangchuanyingyezhizhao: '请上传营业执照',
  huoquyanzhengma: '获取验证码',
  qingtianxieshoujihao: '请填写手机号',
  qingshezhinidemima: '请设置你的密码',
  qingtianxieshoujiyanzhengma: '请填写手机验证码',
  qingzhishaoshangchuanyizhang: '请至少上传一张图片',
  qingtianxietuxingmima: '请填写图形验证码',
  zhucechenggong: '注册成功',
  shoujigeshiyouwu: '手机号格式有误',
  dengluchenggong: '登录成功',
  qingxiugaimima: '请修改密码',
  qingquerennidexinmima: '请确认您的新密码',
  xiugaichenggong: '修改成功',
  qingshurunideyouxiang: '请输入您的邮箱',
  youxianggeshiyouwu: '邮箱格式有误',
  qingtianxieyouxiangyanzhengma: '请填写邮箱验证码',
  shuruzihao: '请输入字号',
  shuruyouxiang: '请输入邮箱',
  Eyouxiang: '邮箱',
  chakangengduo: '查看更多',
  fabushijian: '发布时间',
  shangyipian: '上一篇',
  xiayipian: '下一篇',
  meiyougengduole: '没有更多了',
  zanwupingzheng: '暂无凭证',
  qingxianshangchuanpingzheng: '请先上传凭证',
  xuyaotianxieyonghu: '需要填写用户注册时所用的邮箱',

  niyou: '您有',
  gexindedingdan:'个新的订单',
  qingjishichashou: '请及时查收',

  tianjiadingdanchenggong: '添加订单成功',
  bianjidingdanchenggong: '编辑订单成功',

  quxiaochenggong: '取消成功',

}