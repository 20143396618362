export const m = {
  index: 'Home Page', // 导航-首页
  about: 'About Us', // 导航-关于我们
  business: 'Company Business', // // 导航-公司业务
  business2: 'Co. Biz', // // 导航-公司业务
  case: 'Member Units', // 导航-合作案例
  news: 'News & Info', // 导航-新闻资讯
  call: 'Contact Us', // 导航-联系我们
  sdInfo: 'S&D Info',
  Login: 'Login', // 导航-登录
  Registration: 'Reg', // // 导航-注册
  brief: 'Zhengzhou Xinrongdeng International Trade Co., Ltd. is a trading company that integrates import and export. In terms of grain import, it operates varieties such as wheat, buckwheat, oats, mung beans, peas, flaxseeds, etc; At the same time, we are conducting export agency business for domestic advanced technology agricultural machinery companies. Currently, the main types of agricultural machinery include cloud controlled air suction seeders, drive rakes, hydraulic flip plows, etc.',
  trade: 'Import and export of goods', // 公司业务 - 贸易服装
  equipment: 'Tech. import/export', // 公司业务 - 医用电子仪器设备
  jewelry: 'Import/export agency', // 公司业务 - 鞋帽饰品
  tools: 'Food imp./exp', //  公司业务 - 五金工具
  quick: 'Quick Navigation',
  find: 'Find Us',
  address: 'Room 202, 2nd Floor, No. 160-9, 8th Street, Zhengzhou Area (Economic Development Zone), Henan Pilot Free Trade Zone',
  service: 'Consultation Hotline',
  filing: 'Zhengzhou Xinrongdeng International Trade Co.',
  filing2: 'Jinggongwang Anbei No. 110123456789456',
  vx: 'WeChat',
  addressText: 'Company Address',
  iponeType: 'Contact Info',
  zhuceyouxiang:'Registered Email',
  emil: 'Company Email',
  console: 'Console',

  cIndex: 'Home Page',
  cOrder: 'Order Management',
  cUserInfo: 'Personal Information',
  weclonme: 'Welcome',
  backIndex: 'Return to the official site',
  tuiChu: 'Exit',
  fontSize: 'Font Size',
  phone: 'Phone Number',
  chaxun: 'Query',
  yongHuXiangQing: 'User Details',
  userHeard: 'Avatar',
  gongsixiangqing: 'Company Info',
  jingYingPingZhong: 'Biz Variety',
  guanbi: 'Close',
  queren: 'Confirm',
  jiChuXinXi: 'Basic Information',
  duiGongZhangHaoXinXi: 'Corporate account information',
  tianJiaDuiGongZhangHao: 'Add corp acct info',
  biZhong: 'Currency',
  shouxirenmingcheng: 'Beneficiary Name',
  shouxirendizhi: 'Beneficiary Address',
  shouxirenzhanghao: 'Beneficiary Account',
  shouxirenkaihuhang: 'Beneficiary Account Bank',
  kaihuhangdizhi: 'Bank Address ',
  shanchu: 'Delete',
  tijiao: 'Submit',
  quxiao: 'Cancel',
  tan1: 'Are you sure to delete this corporate account information?',
  tishi: 'Prompt',
  shanchuchenggong: 'Delete Successful!',
  qingshurubizhong: 'Please enter currency',
  qingshurushouyirenmingren: 'Please enter the beneficiary name',
  qingshurushouqirendizhi: 'Please enter the beneficiary address',
  qingshurushouqirenzhanghao: 'Please enter the beneficiary account',
  qingshurushouqirenkaihuhang: 'Please enter the beneficiary account bank',
  qingshurukaihuhangdizhi: 'Please enter the bank address',
  qingshuruzihao: 'Please enter the font size',
  qingshurulianxifangshi: 'Please enter your contact information',
  qingshurujingyingpinzhong: 'Please enter the business type',
  qingshangchuantouxiang: 'Please upload your profile picture',
  qingshurugongsixiangqing: 'Please enter company details',
  cPhone: 'Phone',
  zhuangtai: 'Status',
  qingxuanzezaixianzhuangtai: 'Please select order status',
  type1: 'Whole',
  type2: 'To be sent',
  type3: 'To be paid',
  type4: 'Paid',
  type5: 'Completed',
  type6: 'Canceled',
  chongzhi: 'Reset',
  tianjiadingdan: 'Add Order',
  daochu: 'Export',
  shuaxin: 'Refresh',
  noneOrder: 'No Orders~',
  xuhao: 'No.',
  dingdanhao: 'Order Number',
  hetonghao: 'Contract Number',
  shangpinmingcheng: 'Product Name',
  shangpinmiaoshu: 'Product Descrip',
  fukuanjiage: 'PYMT',
  zhifushijian: 'Payment Time',
  wu: 'None',
  dingdanzhuangtai: 'Ord. Sts.',
  zhifupingzheng: 'Payment Voucher',
  chuangjianshijian: 'Creation Time',
  zhiliangguige: 'Qual. Spec.',
  jiaohuoqi: 'Delivery Time',
  zhifufangshi: 'Payment Method',
  chakanzhuangtai: 'View Status',
  Viewed: 'Viewed',
  Viewed2: 'Not Viewed',
  caozuo: 'Operation',
  edit: 'Editing',
  fasong: 'Send',
  shangchuanpinzheng: 'Upload Credentials',
  wanchengdingdan: 'Complete Order',
  querenquxiao: 'Confirm Cancellation?',
  qingtianxiequxiaoyuanyin: 'Please fill in the reason for cancellation',
  querenfasongdingdan: 'Confirm sending order?',
  fasongchenggong: 'Sending successfully',
  querenwanchengdingdan: 'Confirm completion of order?',
  dingdanwancheng: 'Order Completed',
  shuaxinchenggong: 'Refresh Successful',
  jiage: 'Price',
  xuanzeriqi: 'Select Date',
  pay1: 'Telegraphic Transfer',
  pay2: 'D/P immediate',
  pay3: 'D/P30 days',
  pay4: 'Sight letter of credit',
  fasonggeiyonghu: 'Send to user',
  qingxuanzefasonggeiyonghu: 'Please enter the email address of the user you want to send to',
  pingzheng: 'Voucher',
  shangchuanchenggong: 'Upload successful',
  daochuxuanze: 'Export Selection',
  yonghushoujihao: 'User Email',
  kaishishijian: 'Start Time',
  jieshushijian: 'End Time',
  zuihouyige: 'If left blank, all users will be exported',
  bianjidingdan: 'Edit Order',
  tijiaochenggong: 'Submitted Successfully',

  loginTitle: 'Welcome back, please login to your account',
  qingshurunidezhanghao: 'Please enter your phone number',
  qingshurunidemima: 'Please enter your password',
  qingshuyanzhengma: 'Pls enter verif. code',
  wangjimima: 'Forgot Password',
  qingzhucehuiyuanzhanghao: 'Please register a member account',
  qingshangchuanyingyezhizhao: 'Pls upload bus. lic.',
  huoquyanzhengma: 'Get CAPTCHA',
  qingtianxieshoujihao: 'Please fill in your phone number',
  qingshezhinidemima: 'Please set your password',
  qingtianxieshoujiyanzhengma: 'Please fill in the mobile verification code',
  qingzhishaoshangchuanyizhang: 'Please upload at least one image',
  qingtianxietuxingmima: 'Please fill in the graphic verification code',
  zhucechenggong: 'Login was successful',
  shoujigeshiyouwu: 'The phone number format is incorrect',
  dengluchenggong: 'Login successfully',
  qingxiugaimima: 'Please change your pwd',
  qingquerennidexinmima: 'Please confirm your new pwd',
  xiugaichenggong: 'Modified successfully',
  qingshurunideyouxiang: 'Please enter your email add.',
  youxianggeshiyouwu: 'Email format error',
  qingtianxieyouxiangyanzhengma: 'Please fill in the email verification code',
  shuruzihao: 'Please enter a font size',
  shuruyouxiang: 'Please enter email',
  Eyouxiang:'Email',
  chakangengduo: 'View more',
  fabushijian: 'Release time',
  shangyipian: 'Previous article',
  xiayipian: 'Next article',
  meiyougengduole: 'There is nothing more left',
  zanwupingzheng: 'No credentials',
  qingxianshangchuanpingzheng: 'Please upload your credentials first',
  xuyaotianxieyonghu: 'Need to fill in the email address used by the user to register',

  yaofasongdeyonghubucunzai: 'The user you want to send to does not exist, please check the mailbox',
  dangqianyouxiangweizhuce: 'Current email not yet registered',
  yanzhengmabuzhengque: 'Captcha is incorrect',
  yonghubucunzai: 'The user does not exist',
  yonghuyibeidongjie: 'Users have been frozen',
  mimabuzhengque: 'Incorrect password',

  yanzhengmashixiao: 'Captcha not working, please get it again',
  nihaimeiyouhuoquyanzhengma: 'You haven it got the verification code yet',
  qingshangchuanyingyezhizhao2: 'Please upload business license',
  dangqianyouxiangyibeizhuce: 'The current mailbox is already registered',

  liangcimimashurubuyizhi: 'Inconsistency between two password entries',
  dangqianyonghubucunzai: 'The current user does not exist',

  qingtianxiejingyingpinzhong: 'Please fill in the type of business',

  bunengfasonggeiziji: 'Can it send it to myself!',
  dngdanbucunzai: 'Order does not exist',
  nimeiyouquanxiancaozuo: 'You do not have permission to operate',
  daifasongcaikeyixiugai: 'Waiting to be sent before modifying',
  yaofasongdeyonghubucunzaiqingjianchayx: 'The user you want to send to does not exist, please check the mailbox',

  dingdanbucunzai: 'Order does not exist',
  daifasongcaikeyiquxiao: 'Can only be canceled if it is to be sent',
  yizhifucaikeyiwancheng: 'Paid to complete',
  nihaiweitianjiabizhongqingtianjia: 'You have not added a currency, please do so',
  gaizhuangtaibukecaozuo: 'This state is not operable',

  qingshangchuanzhifupingzheng: 'Please upload proof of payment!',
  niyishangchuanguopingzheng: 'You have already uploaded your credentials',

  niyou: 'You have',
  gexindedingdan:'new orders',
  qingjishichashou: 'Please check back in time',

  yanzhengmayifasong: 'Verification code sent',

  tianjiadingdanchenggong: 'Add Order Success',
  bianjidingdanchenggong: 'Edit Order Success',

  quxiaochenggong: 'Canceled successfully',
}