// 第一步引入插件(本质是一个构造函数)
import VueRouter from 'vue-router'

// 第二步创建router实例对象并暴露
const router = new VueRouter({
  routes: [
    {
      path: '',
      redirect: "/homeNav/homeIndex"
      // redirect: "/endIndex/home"
    },
    {
      path: '/endIndex',
      component: () => import('@/views/backend/endIndex'),
      children: [{
        path: 'home',
        component: () => import('@/views/backend/endHome'),
      },
      {
        path: 'order',
        component: () => import('@/views/backend/endOrder'),
      },
      {
        path: 'useInfo',
        component: () => import('@/views/backend/useInfo'),
      }
      ]
    },
    {
      path: '/homeNav',
      component: () => import('@/views/homeNav/homeNav'),
      children: [
        {
          path: 'homeIndex',
          component: () => import('@/views/homeNav/homePage/homeIndex'),
        },
        {
          path: 'aboutUs',
          component: () => import('@/views/homeNav/homePage/aboutUs'),
        },
        {
          path: 'news',
          component: () => import('@/views/homeNav/homePage/newsInforma'),
        },
        {
          path: 'supply',
          component: () => import('@/views/homeNav/homePage/supplyDemand'),
        }
      ]
    },
    {
      path: '/loginPage',
      component: () => import('@/views/loginPage/loginPage')
    },
    {
      path: '/Reg',
      component: () => import('@/views/registerPage/registerPage')
    },
    {
      path: '/upPassword',
      component: () => import('@/views/upPassword/upPassword')
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path === '/homeNav/homeIndex') {
  next(); // 不需要重定向到首页
} else if (to.matched.length === 0) {
  next('/homeNav/homeIndex'); // 未匹配到路由，重定向到首页
} else {
  // 检查用户是否已登录
  const token = localStorage.getItem('token'); // 假设登录状态保存在 localStorage 中的 token 中
  if (to.path.startsWith('/endIndex') && !token) {
    // 如果用户访问的是需要登录才能看到的页面，并且没有 token，则重定向到登录页
    next('/loginPage');
  } else {
    // 其他情况正常跳转
    next();
  }
}

});

export default router